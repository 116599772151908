import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'

import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'

import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory'
import * as Sentry from '@sentry/nextjs'
import unfetch from 'unfetch'
import introspectionQueryResultData from '../fragmentTypes.json'
import config from '../config'
import { loginToken } from '../utils'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const authLink = setContext((_, { headers }) =>
  loginToken.getWithExpiryCheck().then(token => {
    let h = headers
    if (token) {
      h = {
        ...headers,
        Authorization: `Bearer ${token}`,
      }
    }

    return {
      headers: h,
    }
  })
)

/* This link was introduced to retry queries fired with an expired token
 * which was an observed behaviour on inactive and long-standing sessions.
 * The UX was probably unaffected, but the reported errors were confusing.
 * A 401 error means that the request never got to the GraphQL resolvers,
 * so there's not worries of unintended side effects, but this Link shouldn't be
 * used for any other kind of network errors without careful consideration.
 */

const queriesToRetry = ['CurrentUserContext']

const authRetryLink = new RetryLink({
  attempts: {
    max: 3, // instead of the default 5
    retryIf: (error, operation) => {
      return error.statusCode === 401 && queriesToRetry.includes(operation.operationName)
    },
  },
})

const errorLink = onError(
  ({ operation: { operationName, variables }, graphQLErrors, networkError }) => {
    try {
      const data = { operation: { operationName, variables }, graphQLErrors, networkError }

      let msg = `GraphQL Error: ${operationName}`

      if (networkError && networkError.message) {
        msg = `${msg} - ${networkError.message}`
      } else if (graphQLErrors && graphQLErrors.length > 0) {
        const status = (graphQLErrors[0].extensions && graphQLErrors[0].extensions.code) || 'NULL'
        msg = `${msg} -  ${status} - ${graphQLErrors[0].message}`
      }
      const level = networkError ? 'warning' : 'error'

      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('errorLink: Sentry.captureMessage', { error: msg, extra: data, level })
      }

      Sentry.withScope(scope => {
        Object.keys(data).forEach(key => scope.setExtra(key, JSON.stringify(data[key])))
        Sentry.captureMessage(msg, level)
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in errorLink')
      Sentry.captureException(error)
    }
  }
)

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: object => {
    switch (object.__typename) {
      // A user only has one set of notification settings
      // This lets the Apollo cache watch and merge updates
      // even without an ID
      case 'UserNotificationSettings':
        return 'UserNotificationSettings:singleton'
      case 'WsConnectionStatus':
        return 'WsConnectionStatus:singleton'
      /* In the current architecture both types of searches use the same set of IDs,
       * so the dataId can be generalized and not include the original __typename.
       * (This will make it easier to update the cache when an unsaved search is saved.) */
      case 'UnsavedListingSearch':
      case 'SavedListingSearch':
        return `ListingSearch:${object.id}@${object.version}`
      default:
        return defaultDataIdFromObject(object) // fall back to default handling
    }
  },
})

let client

const defaultOptions = {
  watchQuery: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

const httpLink = new HttpLink({
  uri: `${config.apiPath}/graphql`,
  fetch: unfetch,
})

// The auth retry link has to be before the authLink so that the authLink is used
// on every retry.
client = new ApolloClient({
  cache,
  resolvers: {},
  link: errorLink.concat(authRetryLink).concat(authLink).concat(httpLink),
  credentials: 'include',
  connectToDevTools: true,
  defaultOptions,
})

const client_ = client
export default client_
