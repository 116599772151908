// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Utils = require("../../utils/Utils.bs.js");
var React = require("react");
var Sentry = require("../Sentry.bs.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var FeaturesV3_EvaluateFlagsV5Mutation_graphql = require("../../__generated__/FeaturesV3_EvaluateFlagsV5Mutation_graphql.bs.js");

var features = {
  hd: [
    "BillingPreferencePlanDowngrade",
    "billing_preference_plan_downgrade"
  ],
  tl: {
    hd: [
      "CancelProMembership",
      "cancel_pro_membership"
    ],
    tl: {
      hd: [
        "ConversationMessageStatus",
        "conversation_message_status"
      ],
      tl: {
        hd: [
          "DuplicateListings",
          "duplicate_listings"
        ],
        tl: {
          hd: [
            "MLSCheckboxesAlwaysEnabled",
            "mls_checkboxes_always_enabled"
          ],
          tl: {
            hd: [
              "SaveAsBuyer",
              "save_as_buyer"
            ],
            tl: {
              hd: [
                "SetConversationAnonymousStatus",
                "set_conversation_anonymous_status"
              ],
              tl: {
                hd: [
                  "ValidatePersonalInfoInDescription",
                  "validate_personal_info_in_description"
                ],
                tl: {
                  hd: [
                    "HiddenSellers",
                    "hidden_sellers"
                  ],
                  tl: {
                    hd: [
                      "UnhideSeller",
                      "unhide_seller"
                    ],
                    tl: {
                      hd: [
                        "CAP_ClientRegistration",
                        "cap_client_registration"
                      ],
                      tl: {
                        hd: [
                          "SavedSearches",
                          "saved_searches"
                        ],
                        tl: {
                          hd: [
                            "RenameClient",
                            "rename_client"
                          ],
                          tl: {
                            hd: [
                              "OffMlsFilterForCAP",
                              "off_mls_filter_for_cap"
                            ],
                            tl: {
                              hd: [
                                "MarketingProfileSettings",
                                "marketing_profile_settings"
                              ],
                              tl: {
                                hd: [
                                  "SwitchTeamToAgentMarketingProfile",
                                  "switch_team_to_agent_marketing_profile"
                                ],
                                tl: {
                                  hd: [
                                    "RelayConversationsPage",
                                    "relay_conversations_page"
                                  ],
                                  tl: {
                                    hd: [
                                      "RelayEmailNotificationsPage",
                                      "relay_email_notifications_page"
                                    ],
                                    tl: {
                                      hd: [
                                        "RelaySubscribePage",
                                        "relay_subscribe_page"
                                      ],
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function fromString(v) {
  return Core__List.getAssoc(Core__List.map(features, (function (param) {
                    return [
                            param[1],
                            param[0]
                          ];
                  })), v, (function (prim0, prim1) {
                return prim0 === prim1;
              }));
}

function key(t) {
  return Core__Option.getExn(Core__List.getAssoc(features, t, (function (prim0, prim1) {
                    return prim0 === prim1;
                  })));
}

var allKeys = Core__List.map(features, (function (param) {
        return param[1];
      }));

var Feature = {
  features: features,
  fromString: fromString,
  key: key,
  allKeys: allKeys
};

var overrides = (JSON.parse(process.env.NEXT_PUBLIC__FEATURES__));

function get(param) {
  return overrides[param];
}

function override(param) {
  var feature = param[0];
  var override$1 = overrides[key(feature)];
  return [
          feature,
          Core__Option.isSome(override$1) ? override$1 : param[1]
        ];
}

var x = Core__List.fromArray(Utils.$$Array.stripNones(Object.keys(overrides).map(function (k) {
              var f = fromString(k);
              return Core__Option.map(f, (function (f$p) {
                            return [
                                    f$p,
                                    overrides[k]
                                  ];
                          }));
            })));

var $$default = Core__List.length(x) > 0 ? x : undefined;

var Override = {
  overrides: overrides,
  get: get,
  override: override,
  $$default: $$default
};

function variant(f, t) {
  return Core__Option.flatMap(Core__List.getAssoc(t, f, (function (prim0, prim1) {
                    return prim0 === prim1;
                  })), (function (x) {
                return x;
              }));
}

function make(jsProps) {
  return Core__List.fromArray(Core__Array.filterMap(jsProps, (function (e) {
                    var flagKey = fromString(e.flagKey);
                    if (flagKey !== undefined) {
                      return [
                              flagKey,
                              e.variantKey
                            ];
                    }
                    
                  })));
}

var Features = {
  variant: variant,
  make: make
};

var context = React.createContext(undefined);

function useFeatureFlipperState(refresh, features, token, initializing, isLoggingOut) {
  var match = React.useState(function (param) {
        
      });
  var setState = match[1];
  var prevTokenRef = React.useRef(token);
  React.useEffect((function (param) {
          if (features !== undefined) {
            Curry._1(setState, (function (param) {
                    return features;
                  }));
          }
          
        }), [features]);
  React.useEffect((function (param) {
          var match = prevTokenRef.current;
          if (match !== undefined) {
            
          } else if (token !== undefined) {
            if (isLoggingOut) {
              
            } else {
              Curry._1(refresh, undefined);
            }
          } else if (initializing || isLoggingOut) {
            
          } else {
            Curry._1(refresh, undefined);
          }
          Utils.React.setCurrentRef(prevTokenRef, token);
        }), [
        token,
        initializing,
        isLoggingOut
      ]);
  return match[0];
}

function useFeatureFlipper(flagKey) {
  var features = React.useContext(context);
  return Core__Option.flatMap(features, (function (param) {
                return variant(flagKey, param);
              }));
}

var convertVariables = FeaturesV3_EvaluateFlagsV5Mutation_graphql.Internal.convertVariables;

var convertResponse = FeaturesV3_EvaluateFlagsV5Mutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = FeaturesV3_EvaluateFlagsV5Mutation_graphql.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, FeaturesV3_EvaluateFlagsV5Mutation_graphql.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, FeaturesV3_EvaluateFlagsV5Mutation_graphql.node, convertResponse, convertWrapRawResponse, param);
}

var EvaluateFlagsV5Mutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

var make$1 = context.Provider;

var Provider = {
  make: make$1
};

function FeaturesV3$Provider(props) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function (param) {
        
      });
  var setFeatures = match$1[1];
  var features = match$1[0];
  var refresh = React.useCallback((function (param) {
          return new Promise((function (resolve, _reject) {
                        Curry._8(mutate, {
                              keys: Core__List.toArray(allKeys)
                            }, undefined, undefined, undefined, (function (response, _mutationErrors) {
                                var flags = response.evaluateFlagsV5;
                                if (flags !== undefined) {
                                  var features = make(Core__Array.keepSome(flags));
                                  Curry._1(setFeatures, (function (param) {
                                          return features;
                                        }));
                                } else {
                                  Curry._1(setFeatures, (function (param) {
                                          
                                        }));
                                }
                                resolve();
                              }), (function (e) {
                                Sentry.captureException(new Error(e.message), "FeaturesV3.res", 188, {
                                      exn: e
                                    }, undefined);
                                Curry._1(setFeatures, (function (param) {
                                        
                                      }));
                                resolve();
                              }), undefined, undefined);
                      }));
        }), [mutate]);
  var features$1 = React.useMemo((function (param) {
          var features$2 = Core__Option.map(features, (function (x) {
                  return Core__List.map(x, override);
                }));
          if (Core__Option.isSome(features$2)) {
            return features$2;
          } else {
            return $$default;
          }
        }), [features]);
  var features$2 = useFeatureFlipperState(refresh, features$1, props.token, props.initializing, props.isLoggingOut);
  return JsxRuntime.jsx(make$1, {
              value: features$2,
              children: props.children
            });
}

var Provider$1 = {
  Provider: Provider,
  make: FeaturesV3$Provider
};

function make$2(param) {
  var token = Caml_option.nullable_to_opt(param.token);
  return JsxRuntime.jsx(FeaturesV3$Provider, {
              token: token,
              initializing: param.initializing,
              isLoggingOut: param.isLoggingOut,
              children: param.children
            });
}

var JsProvider = {
  make: make$2
};

function useSavedSearchesEnabled(param) {
  return useFeatureFlipper("SavedSearches") === "on";
}

function useRelayConversationsPageEnabled(param) {
  return useFeatureFlipper("RelayConversationsPage") === "on";
}

exports.Feature = Feature;
exports.Override = Override;
exports.Features = Features;
exports.context = context;
exports.useFeatureFlipperState = useFeatureFlipperState;
exports.useFeatureFlipper = useFeatureFlipper;
exports.EvaluateFlagsV5Mutation = EvaluateFlagsV5Mutation;
exports.Provider = Provider$1;
exports.JsProvider = JsProvider;
exports.useSavedSearchesEnabled = useSavedSearchesEnabled;
exports.useRelayConversationsPageEnabled = useRelayConversationsPageEnabled;
/* allKeys Not a pure module */
